var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.field !== null
      ? _c(
          "div",
          [
            _vm.field.type.startsWith("Enum<")
              ? _c("b-form-select", {
                  staticClass: "ml-0",
                  attrs: {
                    options: _vm.getOptions(_vm.field),
                    disabled: _vm.disabled,
                  },
                  on: { input: _vm.handleInput },
                  model: {
                    value: _vm.item,
                    callback: function ($$v) {
                      _vm.item = $$v
                    },
                    expression: "item",
                  },
                })
              : _vm._e(),
            _vm.field.type === "String"
              ? _c(
                  "div",
                  [
                    _c("b-form-input", {
                      attrs: { size: "md", readonly: _vm.disabled },
                      on: { input: _vm.handleInput },
                      model: {
                        value: _vm.item,
                        callback: function ($$v) {
                          _vm.item = $$v
                        },
                        expression: "item",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.field.type === "Float" ||
            _vm.field.type === "Double" ||
            _vm.field.type === "Integer" ||
            _vm.field.type === "Byte" ||
            _vm.field.type === "Long"
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("b-form-input", {
                      attrs: { size: "md", readonly: _vm.disabled },
                      on: { input: _vm.handleInput, keydown: _vm.numberKey },
                      model: {
                        value: _vm.item,
                        callback: function ($$v) {
                          _vm.item = $$v
                        },
                        expression: "item",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.field.type === "Boolean"
              ? _c(
                  "div",
                  { staticClass: "d-flex filter-radio-buttons" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "ml-1",
                        attrs: { disabled: _vm.disabled },
                        on: { input: _vm.handleInput },
                        model: {
                          value: _vm.item,
                          callback: function ($$v) {
                            _vm.item = $$v
                          },
                          expression: "item",
                        },
                      },
                      [
                        _c("span", { staticClass: "mr-2" }, [
                          _vm._v(_vm._s(_vm.field.displayName)),
                        ]),
                        _vm.field.description
                          ? _c(
                              "span",
                              [
                                _c("font-awesome-icon", {
                                  style: {
                                    color: "var(--form-control-placeholder)",
                                    fontSize: "0.9em",
                                  },
                                  attrs: {
                                    id: _vm.componentId + "_" + _vm.field.name,
                                    icon: ["far", "circle-question"],
                                  },
                                }),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      target:
                                        _vm.componentId + "_" + _vm.field.name,
                                      triggers: "hover",
                                      placement: "top",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.field.description) + " "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.field.type === "Date"
              ? _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("b-form-datepicker", {
                      staticClass: "date-height",
                      attrs: {
                        "today-button": "",
                        "reset-button": "",
                        "close-button": "",
                        "hide-header": "",
                        "label-today-button": _vm.$t("date.today"),
                        "label-reset-button": _vm.$t("date.reset"),
                        "label-close-button": _vm.$t("date.close"),
                        "today-button-variant": "primary",
                        "reset-button-variant": "danger",
                        "close-button-variant": "secondary",
                        disabled: _vm.disabled,
                        boundary: "viewport",
                        "popper-opts": { positionFixed: true },
                      },
                      on: { input: _vm.handleInput },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function (ref) {
                              return [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "calendar-days"] },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2621928167
                      ),
                      model: {
                        value: _vm.item,
                        callback: function ($$v) {
                          _vm.item = $$v
                        },
                        expression: "item",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "b-form-invalid-feedback",
              {
                staticClass: "alert-danger form-field-alert",
                class: { "d-block": _vm.showItemError },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-exclamation"] },
                }),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.$t("error.cannot_be_blank", [_vm.field.displayName])
                    ) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "b-form-invalid-feedback",
              {
                staticClass: "alert-danger form-field-alert",
                class: { "d-block": _vm.showRuleError },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-exclamation"] },
                }),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.$t("error.validation_failed", [_vm.field.regex])
                    ) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "b-form-invalid-feedback",
              {
                staticClass: "alert-danger form-field-alert",
                class: { "d-block": _vm.showMinError },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-exclamation"] },
                }),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.$t("fields.error.less_than_min", [_vm.field.min])
                    ) +
                    " "
                ),
              ],
              1
            ),
            _c(
              "b-form-invalid-feedback",
              {
                staticClass: "alert-danger form-field-alert",
                class: { "d-block": _vm.showMaxError },
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "circle-exclamation"] },
                }),
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.$t("fields.error.greater_than_max", [_vm.field.max])
                    ) +
                    " "
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }